import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh fingerprint`}</strong>{` -- print the fingerprint of an SSH public key or certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh fingerprint <file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh fingerprint`}</strong>{` prints the fingerprint of an ssh public key or
certificate.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`file`}</inlineCode>{`
The path to an SSH public key or certificate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--format`}</strong>{`=`}<inlineCode parentName="p">{`format`}</inlineCode>{`
The `}<inlineCode parentName="p">{`format`}</inlineCode>{` of the fingerprint, it must be "hex", "base64", "base64-url", "base64-raw", "base64-url-raw" or "emoji".`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Print the fingerprint for a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh fingerprint id_ecdsa-cert.pub
`}</code></pre>
    <p>{`Print the fingerprint for an SSH public key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh fingerprint id_ecdsa.pub
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      